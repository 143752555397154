<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Пользователь
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Личные данные</FormCardTitle>
          <FormInputBlock>
            <ValidationInputField
              label="Телефон"
              :disabled="
                (currentRole == 70 &&
                  is_phone_confirmed == false &&
                  is_phone_confirmed == true) ||
                  (currentRole == 60 && is_phone_confirmed == true) ||
                  (currentRole == 30 && is_phone_confirmed == true)
              "
              validate-name="телефон"
              rules="required"
              mask="+7 (9##) ###-##-##"
              v-model="phone_number"
            />
            <ValidationInputField
              v-if="currentRole == 70"
              v-model="last_name"
              label="Фамилия"
              validate-name="фамилия"
            />
            <ValidationInputField
              v-if="currentRole == 70"
              v-model="first_name"
              label="Имя"
              validate-name="имя"
            />
            <ValidationInputField
              v-if="currentRole == 70"
              v-model="middle_name"
              label="Отчество"
              validate-name="отчество"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editUser)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CreateUserPage",
  components: {
    FormCardTitle,
    ValidationInputField,
    FormInputBlock,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false
    };
  },
  created() {
    this.$store.dispatch("prepareGeneralEditUser", {
      id: this.$route.params.id
    });
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    is_phone_confirmed: {
      get() {
        return this.$store.getters.getUserEditForm.is_phone_confirmed.value;
      }
    },
    phone_number: {
      get() {
        return this.$store.getters.getUserEditForm.phone_number.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserValue", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    first_name: {
      get() {
        return this.$store.getters.getUserEditForm.first_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserValue", {
          fieldName: "first_name",
          value: newValue
        });
      }
    },
    last_name: {
      get() {
        return this.$store.getters.getUserEditForm.last_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserValue", {
          fieldName: "last_name",
          value: newValue
        });
      }
    },
    middle_name: {
      get() {
        return this.$store.getters.getUserEditForm.middle_name.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserValue", {
          fieldName: "middle_name",
          value: newValue
        });
      }
    }
  },
  methods: {
    editUser() {
      this.loading = true;
      this.$store
        .dispatch("editGeneralUser", { id: this.$route.params.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.user)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
